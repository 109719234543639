import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import './plugins/bootstrap-vue'
import App from './App.vue'
import { BootstrapVue, BootstrapVueIcons, IconsPlugin } from 'bootstrap-vue'
import VueRouter from 'vue-router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'








Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueRouter)

Vue.use(IconsPlugin)
Vue.use(VueAxios, axios)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'



/* APARTE DE AGREGAR LAS RUTAS AQUI, TAMBIEN HAY QUE AGREGARLAS AL ARCHIVO vue.config.js*/
var router = new VueRouter({
  routes: [
    /*Usuario*/
    {
      path: '/',
      component: () => import('@/components/user/Index.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/encuestador2',
      component: () => import('@/components/user/Encuestador2.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/coordinador',
      component: () => import('@/components/user/Coordinador.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/administrador',
      component: () => import('@/components/user/Administrador.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/dcuestionarios',
      component: () => import('@/components/user/Dcuestionarios.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/dencuestas',
      component: () => import('@/components/user/Dencuestas.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/levantamiento',
      component: () => import('@/components/user/Levantamiento.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
      path: '/probador',
      component: () => import('@/components/user/Probador.vue'),
      meta: { 
        requiresAuth: false
      }
    },
    {
    path: '/probador2',
    component: () => import('@/components/user/Probador2.vue'),
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/dencuestas2',
    component: () => import('@/components/user/Dencuestas2.vue'),
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/dcuestionarios2',
    component: () => import('@/components/user/Dcuestionarios2.vue'),
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/pruebas01',
    component: () => import('@/components/user/Pruebas01'),
    meta: { 
      requiresAuth: false
    }
  },





  /*PARTE PRUEBAS VALES GASOLINA*/

  {
    path: '/vales_gasolina',
    component: () => import('@/components/user/Vales_gasolina'),
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/vales_gasolina2',
    component: () => import('@/components/user/Vales_gasolina2'),
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/vales_gasolina_vehiculos',
    component: () => import('@/components/user/Vales_gasolina_vehiculos'),
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/vales_gasolina_personas',
    component: () => import('@/components/user/Vales_gasolina_personas'),
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/vales_gasolina_centros_carga',
    component: () => import('@/components/user/Vales_gasolina_centros_carga'),
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/vales_gasolina_departamentos',
    component: () => import('@/components/user/Vales_gasolina_departamentos'),
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/vales_gasolina_emision_tickets',
    component: () => import('@/components/user/Vales_gasolina_emision_tickets'),
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/vales_gasolina_consultas',
    component: () => import('@/components/user/Vales_gasolina_consultas'),
    meta: { 
      requiresAuth: false
    }
  },
  {
    path: '/vales_gasolina_costos',
    component: () => import('@/components/user/Vales_gasolina_costos'),
    meta: { 
      requiresAuth: false
    }
  },






  ],
  mode: "history"
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')


